import "./App.css";
import Logo from "../src/assets/img/mmms.png";
// import departments1 from "../src/assets/img/departments-1.jpg";
// import departments2 from "../src/assets/img/departments-2.jpg";
// import departments3 from "../src/assets/img/departments-2.jpg";
// import departments4 from "../src/assets/img/departments-2.jpg";
// import departments5 from "../src/assets/img/departments-2.jpg";
import SwagatMohanty from "../src/assets/img/doctors/swagat-mohanty.jpg";
import SatyajitSamal from "../src/assets/img/doctors/Satyajit-Samal.jpg";
import KiranSabat from "../src/assets/img/doctors/kiran-sabat.jpg";
import DesabandhuBehera from "../src/assets/img/doctors/Desabandhu-Behera.jpg";
import AnimaSethy from "../src/assets/img/doctors/Anima-Sethy.jpg";
import doctors4 from "../src/assets/img/doctors/doctors-1.jpg";
import ShopImage from "../src/assets/img/Store/medicine1.jpg";

import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaHandHoldingHeart,
  FaWhatsapp,
  FaArrowUp,
} from "react-icons/fa";
import {
  FaUserDoctor,
  FaBedPulse,
  FaHospitalUser,
  FaAngleRight,
} from "react-icons/fa6";
import { GiPayMoney, GiMedicines } from "react-icons/gi";
import { TbReport } from "react-icons/tb";
import { GrTest } from "react-icons/gr";
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { TfiSkype } from "react-icons/tfi";
import { CiLocationOn } from "react-icons/ci";
import { IoIosPhonePortrait } from "react-icons/io";
import { BsEnvelope } from "react-icons/bs";

function App() {
  return (
    <>
      {/*  Top Bar  */}
      <div id="topbar" className="d-flex align-items-center fixed-top">
        <div className="container d-flex justify-content-between">
          <div className="contact-info d-flex align-items-center top-nav">
            <BsEnvelope />
            <a href="mailto:sanjay@maamanishamedicines.com">
              sanjay@maamanishamedicines.com
            </a>
            <IoIosPhonePortrait /> +91 9439868692
          </div>
          <div className="d-none d-lg-flex social-links align-items-center">
            <a href="#" className="twitter">
              <FaTwitter />
            </a>
            <a href="#" className="facebook">
              <FaFacebook />
            </a>
            <a href="#" className="instagram">
              <FaInstagram />
            </a>
            <a href="#" className="linkedin">
              <FaLinkedin />
            </a>
          </div>
        </div>
      </div>

      {/*  Header  */}
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <h1 className="logo me-auto">
            <a href="index.html">
              <img src={Logo} alt="" className="img-fluid" />
            </a>
          </h1>
          {/*  <a href="index.html" className="logo me-auto"><img src="assets/img/logo.png" alt="" className="img-fluid"></a> */}

          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li>
                <a className="nav-link scrollto active" href="#hero">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#services">
                  Services
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#doctors">
                  Doctors
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {/*  .navbar  */}

          <a href="https://wa.me/918249055908" className="appointment-btn scrollto">
            <span className="d-none d-md-inline chat"> <FaWhatsapp/> Chat With Us</span>
          </a>
        </div>
      </header>
      {/*  End Header  */}

      {/*  Hero Section  */}
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <h1>Welcome to Maa Manisha Medicines</h1>
          <h2>
            We always ensure our customer gets best treatment & Pharma from us.
          </h2>
          <a href="#about" className="btn-get-started scrollto">
            Get Started
          </a>
        </div>
      </section>
      {/*  End Hero  */}

      <main id="main">
        {/*  Why Us Section  */}
        <section id="why-us" className="why-us">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 d-flex align-items-stretch">
                <div className="content">
                  <h3>Why Choose Maa Manisha Medicines?</h3>
                  <p>
                    Discover top-quality healthcare products at our medicine
                    store. Our wide range includes trusted brands, ensuring your
                    well-being. Explore now for health solutions you can rely
                    on. Fast delivery and expert advice for a healthier you!
                  </p>
                  <div className="text-center">
                    <a href="#" className="more-btn">
                      Learn More
                      <FaAngleRight />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 d-flex align-items-stretch">
                <div className="icon-boxes d-flex flex-column justify-content-center">
                  <div className="row">
                    <div className="col-xl-4 d-flex align-items-stretch">
                      <div className="icon-box mt-4 mt-xl-0">
                        <TbReport />
                        <h4>Quality Assurance</h4>
                        <p>
                          We prioritize your health by sourcing products only
                          from reputable brands and manufacturers.
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-4 d-flex align-items-stretch">
                      <div className="icon-box mt-4 mt-xl-0">
                        <GiPayMoney />
                        <h4>Expert Guidance</h4>
                        <p>
                          Our knowledgeable team is ready to provide
                          personalized advice, ensuring you make informed
                          choices for your health.
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-4 d-flex align-items-stretch">
                      <div className="icon-box mt-4 mt-xl-0">
                        <FaUserDoctor />
                        <h4>Fast and Reliable Delivery</h4>
                        <p>
                          Your well-being is our priority. Enjoy prompt and
                          secure delivery services to your doorstep.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  End .content */}
              </div>
            </div>
          </div>
        </section>
        {/*  End Why Us Section  */}

        {/*  Doctors Section  */}
        <section id="doctors" className="doctors">
          <div className="container">
            <div className="section-title">
              <h2>Doctors</h2>
              <p>
                We have the best specialists from around the globe, they bring
                years of experience and offer evidence based treatment to ensure
                the best care for you.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={SwagatMohanty} className="img-fluid" alt="" />
                  </div>
                  <div className="member-info">
                    <h4>Dr. Swagat Mohanty</h4>
                    <span>Neurologist</span>
                    <p>
                      MBBS, DNB (MEDICINE), DM Neurology SCB Medical College &
                      Hospital, Cuttack Regd. No.: 21821
                    </p>
                    <div className="social">
                      <a href="">
                        <FaTwitter />
                      </a>
                      <a href="">
                        <FaFacebook />
                      </a>
                      <a href="">
                        <FaInstagram />
                      </a>
                      <a href="">
                        <FaLinkedin />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mt-4 mt-lg-0">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={KiranSabat} className="img-fluid" alt="" />
                  </div>
                  <div className="member-info">
                    <h4>Dr. Kiran Kumar Sabat</h4>
                    <span> Diabetes & Thyroid Specialist</span>
                    <p>
                    Interventional Brochoscopy & Thoracoscopy, pgdmch, ccdm S.C.B. Medical
                      College & Hospital, Cuttack
                    </p>
                    <div className="social">
                      <a href="">
                        <FaTwitter />
                      </a>
                      <a href="">
                        <FaFacebook />
                      </a>
                      <a href="">
                        <FaInstagram />
                      </a>
                      <a href="">
                        {" "}
                        <FaLinkedin />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mt-4">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={DesabandhuBehera} className="img-fluid" alt="" />
                  </div>
                  <div className="member-info">
                    <h4>Dr. Desabandhu Behera</h4>
                    <span>Diabetes & Nephrology (S.R.)</span>
                    <p>
                      M.D.(Medicine) Assistant Professor S.C.B. Medical College
                      & Hospital, Cuttack Diabetes & Nephrology (S.R.)
                    </p>
                    <div className="social">
                      <a href="">
                        <FaTwitter />
                      </a>
                      <a href="">
                        <FaFacebook />
                      </a>
                      <a href="">
                        <FaInstagram />
                      </a>
                      <a href="">
                        <FaLinkedin />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mt-4">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={doctors4} className="img-fluid" alt="" />
                  </div>
                  <div className="member-info">
                    <h4>Dr. Deepak Ku. Behera</h4>
                    <span>Paediatric</span>
                    <p>
                      Asst. Professor Department of Paediatrics Sishu Bhawan &
                      S.C.B. Medical College, Cuttack
                    </p>
                    <div className="social">
                      <a href="">
                        <FaTwitter />
                      </a>
                      <a href="">
                        <FaFacebook />
                      </a>
                      <a href="">
                        <FaInstagram />
                      </a>
                      <a href="">
                        <FaLinkedin />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mt-4">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={doctors4} className="img-fluid" alt="" />
                  </div>
                  <div className="member-info">
                    <h4>Dr. B. Swagat Ku. Subudhi</h4>
                    <span>GENERAL SURGERY</span>
                    <p>
                      M.B.B.S, M.S (GENERAL SURGERY) Associate Professor, Dept.
                      of Surgery SCB Medical College & Hospital, Cuttack
                    </p>
                    <div className="social">
                      <a href="">
                        <FaTwitter />
                      </a>
                      <a href="">
                        <FaFacebook />
                      </a>
                      <a href="">
                        <FaInstagram />
                      </a>
                      <a href="">
                        <FaLinkedin />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mt-4">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={doctors4} className="img-fluid" alt="" />
                  </div>
                  <div className="member-info">
                    <h4>Dr. Ajit Kumar Kar</h4>
                    <span>Orthopedic Surgeon</span>
                    <p>
                      Arthroscopy & Joint Replacement Surgeon Complex Trauma &
                      Pelvi-Acetabular Surgeon Regd. No.: 17073/0R/09
                    </p>
                    <div className="social">
                      <a href="">
                        <FaTwitter />
                      </a>
                      <a href="">
                        <FaFacebook />
                      </a>
                      <a href="">
                        <FaInstagram />
                      </a>
                      <a href="">
                        <FaLinkedin />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mt-4">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={SatyajitSamal} className="img-fluid" alt="" />
                  </div>
                  <div className="member-info">
                    <h4>Dr. Satyajit Samal</h4>
                    <span>Surgery</span>
                    <p>
                      MS (Surgery), M.Ch. (Urology) Asst. Professor Department
                      of Urology S.C.B. Medical College & Hospital, Cuttack
                    </p>
                    <div className="social">
                      <a href="">
                        <FaTwitter />
                      </a>
                      <a href="">
                        <FaFacebook />
                      </a>
                      <a href="">
                        <FaInstagram />
                      </a>
                      <a href="">
                        <FaLinkedin />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mt-4">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={AnimaSethy} className="img-fluid" alt="" />
                  </div>
                  <div className="member-info">
                    <h4>Dr. Anima Kumari Sethy</h4>
                    <span>MBBS, MS (O&G)</span>
                    <p>
                      Asst. Professor S.C.B. Medical College & Hospital, Cuttack
                      Regd. No. 21284/2016
                    </p>
                    <div className="social">
                      <a href="">
                        <FaTwitter />
                      </a>
                      <a href="">
                        <FaFacebook />
                      </a>
                      <a href="">
                        <FaInstagram />
                      </a>
                      <a href="">
                        <FaLinkedin />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  End Doctors Section  */}

        {/*  About Section  */}
        <section id="about" className="about">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-5 col-lg-6 video-box">
                <img src={ShopImage} alt="store image" />
              </div>

              <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-2 px-lg-5">
                <h3>Optimizing Your Health with Quality Medicines</h3>
                <p>
                  In today's fast-paced world, maintaining good health is
                  crucial. Our medicine store is committed to providing you with
                  top-notch pharmaceutical solutions.
                </p>

                <div className="icon-box">
                  <div className="icon">
                    <GrTest />
                  </div>
                  <h4 className="title">
                    <a href="">Comprehensive Selection</a>
                  </h4>
                  <p className="description">
                    Discover a diverse range of high-quality medicines at [Your
                    Store Name]. From over-the-counter essentials to specialized
                    prescriptions, we've got your health covered.
                  </p>
                </div>

                <div className="icon-box">
                  <div className="icon">
                    <MdOutlineHealthAndSafety />
                  </div>
                  <h4 className="title">
                    <a href="">Expert Guidance</a>
                  </h4>
                  <p className="description">
                    Count on our experienced pharmacists for personalized
                    advice. We believe in empowering you with the knowledge to
                    make informed decisions about your health.
                  </p>
                </div>

                <div className="icon-box">
                  <div className="icon">
                    <FaHandHoldingHeart />
                  </div>
                  <h4 className="title">
                    <a href="">Convenient Services</a>
                  </h4>
                  <p className="description">
                    Accessing medications is easy with our user-friendly online
                    platform and prompt delivery services. Trust Maa Manisha Medicines for all your pharmaceutical needs – your well-being
                    is our priority.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  End About Section  */}

        {/*  Services Section  */}
        <section id="services" className="services">
          <div className="container">
            <div className="section-title">
              <h2>Services</h2>
              <p>
              Exceptional Healthcare Offerings at Maa Manisha
              </p>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="icon-box">
                  <div className="icon">
                    <FaBedPulse />
                  </div>
                  <h4>
                    <a href="">Expert Health Guidance</a>
                  </h4>
                  <p>
                  Receive personalized advice from our experienced pharmacists, ensuring informed decisions about your health journey.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div className="icon-box">
                  <div className="icon">
                    <GiMedicines />
                  </div>
                  <h4>
                    <a href="">Wellness Resources Hub</a>
                  </h4>
                  <p>
                  Access a wealth of health and wellness resources, empowering you with insights into proactive health measures.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <FaHospitalUser />
                  </div>
                  <h4>
                    <a href="">Online Convenience</a>
                  </h4>
                  <p>
                  Enjoy the ease of our user-friendly online platform, facilitating easy ordering and real-time tracking for managing your healthcare needs seamlessly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  End Services Section  */}

        {/*  Departments Section  */}
        {/* <section id="departments" className="departments">
          <div className="container">
            <div className="section-title">
              <h2>Departments</h2>
              <p>
                Magnam dolores commodi suscipit. Necessitatibus eius consequatur
                ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam
                quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
                Quia fugiat sit in iste officiis commodi quidem hic quas.
              </p>
            </div>

            <div className="row gy-4">
              <div className="col-lg-3">
                <ul className="nav nav-tabs flex-column">
                  <li className="nav-item">
                    <a
                      className="nav-link active show"
                      data-bs-toggle="tab"
                      href="#tab-1"
                    >
                      Cardiology
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#tab-2">
                      Neurology
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#tab-3">
                      Hepatology
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#tab-4">
                      Pediatrics
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#tab-5">
                      Eye Care
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-9">
                <div className="tab-content">
                  <div className="tab-pane active show" id="tab-1">
                    <div className="row gy-4">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>Cardiology</h3>
                        <p className="fst-italic">
                          Qui laudantium consequatur laborum sit qui ad sapiente
                          dila parde sonata raqer a videna mareta paulona marka
                        </p>
                        <p>
                          Et nobis maiores eius. Voluptatibus ut enim blanditiis
                          atque harum sint. Laborum eos ipsum ipsa odit magni.
                          Incidunt hic ut molestiae aut qui. Est repellat minima
                          eveniet eius et quis magni nihil. Consequatur dolorem
                          quaerat quos qui similique accusamus nostrum rem vero
                        </p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img src={departments1} alt="" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="tab-2">
                    <div className="row gy-4">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>Et blanditiis nemo veritatis excepturi</h3>
                        <p className="fst-italic">
                          Qui laudantium consequatur laborum sit qui ad sapiente
                          dila parde sonata raqer a videna mareta paulona marka
                        </p>
                        <p>
                          Ea ipsum voluptatem consequatur quis est. Illum error
                          ullam omnis quia et reiciendis sunt sunt est. Non
                          aliquid repellendus itaque accusamus eius et velit
                          ipsa voluptates. Optio nesciunt eaque beatae accusamus
                          lerode pakto madirna desera vafle de nideran pal
                        </p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img src={departments2} alt="" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="tab-3">
                    <div className="row gy-4">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>
                          Impedit facilis occaecati odio neque aperiam sit
                        </h3>
                        <p className="fst-italic">
                          Eos voluptatibus quo. Odio similique illum id quidem
                          non enim fuga. Qui natus non sunt dicta dolor et. In
                          asperiores velit quaerat perferendis aut
                        </p>
                        <p>
                          Iure officiis odit rerum. Harum sequi eum illum
                          corrupti culpa veritatis quisquam. Neque
                          necessitatibus illo rerum eum ut. Commodi ipsam minima
                          molestiae sed laboriosam a iste odio. Earum odit
                          nesciunt fugiat sit ullam. Soluta et harum voluptatem
                          optio quae
                        </p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img src={departments3} alt="" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="tab-4">
                    <div className="row gy-4">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>
                          Fuga dolores inventore laboriosam ut est accusamus
                          laboriosam dolore
                        </h3>
                        <p className="fst-italic">
                          Totam aperiam accusamus. Repellat consequuntur iure
                          voluptas iure porro quis delectus
                        </p>
                        <p>
                          Eaque consequuntur consequuntur libero expedita in
                          voluptas. Nostrum ipsam necessitatibus aliquam fugiat
                          debitis quis velit. Eum ex maxime error in consequatur
                          corporis atque. Eligendi asperiores sed qui veritatis
                          aperiam quia a laborum inventore
                        </p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img src={departments4} alt="" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="tab-5">
                    <div className="row gy-4">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>
                          Est eveniet ipsam sindera pad rone matrelat sando reda
                        </h3>
                        <p className="fst-italic">
                          Omnis blanditiis saepe eos autem qui sunt debitis
                          porro quia.
                        </p>
                        <p>
                          Exercitationem nostrum omnis. Ut reiciendis
                          repudiandae minus. Omnis recusandae ut non quam ut
                          quod eius qui. Ipsum quia odit vero atque qui
                          quibusdam amet. Occaecati sed est sint aut vitae
                          molestiae voluptate vel
                        </p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img src={departments5} alt="" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/*  End Departments Section  */}

        {/*  Contact Section  */}
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title">
              <h2>Contact</h2>
              <p>
              Thank you for your interest in reaching out to us! Whether you have a question, feedback, or simply want to say hello, we're here to assist you. Below are several ways you can get in touch with our team:
              </p>
            </div>
          </div>
          <div>
            <iframe
              style={{ border: "0", width: "100%", height: "350px" }}
              src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d233.6081684911939!2d85.8874234!3d20.4762513!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjDCsDI4JzM0LjUiTiA4NcKwNTMnMTUuMSJF!5e0!3m2!1sen!2sin!4v1710086980312!5m2!1sen!2sin"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>

          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="info">
                  <div className="address">
                    <CiLocationOn />
                    <h4>Location:</h4>
                    <p>
                      Infront of IRIS, Backside of Shrusti Hospital <br />
                      Kathagola Road, Mangalabag, Cuttack-753001
                    </p>
                  </div>

                  <div className="email">
                    <BsEnvelope />
                    <h4>Email:</h4>
                    <p>sanjay@maamanishamedicines.com</p>
                  </div>

                  <div className="phone">
                    <IoIosPhonePortrait />
                    <h4>Call:</h4>
                    <p>+91 9938028361</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  End Contact Section  */}
      </main>
      {/*  End #main  */}
      {/*  Footer  */}
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <h3>Maa Manisha Medicines</h3>
                <p>
                  Infront of IRIS, Backside of Shrusti
                  <br />
                  Hospital Kathagola Road,
                  <br /> Mangalabag, Cuttack-753001
                  <br />
                  <br />
                  <strong>Phone:</strong> +91 9938028361
                  <br />
                  <strong>Email:</strong> sanjay@maamanishamedicines.com
                  <br />
                </p>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <FaAngleRight /> <a href="#">Home</a>
                  </li>
                  <li>
                    <FaAngleRight /> <a href="#">About us</a>
                  </li>
                  <li>
                    <FaAngleRight /> <a href="#">Services</a>
                  </li>
                  <li>
                    <FaAngleRight /> <a href="#">Terms of service</a>
                  </li>
                  <li>
                    <FaAngleRight /> <a href="#">Privacy policy</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <FaAngleRight /> <a href="#">Web Design</a>
                  </li>
                  <li>
                    <FaAngleRight /> <a href="#">Web Development</a>
                  </li>
                  <li>
                    <FaAngleRight /> <a href="#">Product Management</a>
                  </li>
                  <li>
                    <FaAngleRight /> <a href="#">Marketing</a>
                  </li>
                  <li>
                    <FaAngleRight /> <a href="#">Graphic Design</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-6 footer-newsletter">
                <h4>Connect with Maa Manisha for Personalized Support</h4>
                <p>
                For any inquiries, guidance, or assistance, reach out to us. Your well-being is our top priority.
                </p>
                <form action="" method="post">
                  <input type="email" name="email" />
                  <input type="submit" value="Subscribe" />
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="container d-md-flex py-4">
          <div className="me-md-auto text-center text-md-start">
            <div className="copyright">
              &copy; Copyright{" "}
              <strong>
              <a href="https://maamanishamedicines.com/">Maa Manisha Medicines</a>
              </strong>
              . All Rights Reserved
            </div>
            <div className="credits">
              Designed by{" "}
              <a href="https://maamanishamedicines.com/">Swaraj Universe</a>
            </div>
          </div>
          <div className="social-links text-center text-md-right pt-3 pt-md-0">
            <a href="#" className="twitter">
              <FaTwitter />
            </a>
            <a href="#" className="facebook">
              <FaFacebook />
            </a>
            <a href="#" className="instagram">
              <FaInstagram />
            </a>
            <a href="#" className="google-plus">
              <TfiSkype />
            </a>
            <a href="#" className="linkedin">
              <FaLinkedin />
            </a>
          </div>
        </div>
      </footer>
      {/*  End Footer  */}
      {/* <div id="preloader"></div>  */}
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
        <FaArrowUp />
      </a>
    </>
  );
}

export default App;
